import { Col } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import HeadingBack from "../component/HeadingBack";

function Bettalview() {
  const data = useParams();
  const dispatch = useDispatch();

  // getProfileDetails

  // user_details

  const details = useSelector((state) =>
    state?.allapi?.battels_details ? state?.allapi?.battels_details : {}
  );

  console.log(details);

  useEffect(() => {
    dispatch(allapiAction.getbDetails({ _id: data?.id }));

    return () => {};
  }, []);

  function maskNumber(number) {
    if (number) {
      const numberString = number.toString(); // Convert the number to a string
      const maskedPortion = numberString.slice(0, -2).replace(/\d/g, "*"); // Replace all but the last 2 digits with asterisks
      const lastTwoDigits = numberString.slice(-2); // Get the last 2 digits
      return maskedPortion + lastTwoDigits;
    }
    // Combine the masked portion and last 2 digits
  }

  return (
    <div className="code-box">
      <div className="flex">
        <div>
          <HeadingBack
            title={"Battles"}
            // subHeading={`Total ${users_list?.totalDocs || 0} Users`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => navigate("/user/add")}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div> */}
      </div>
      <div className="d-flex flex-column flex-column-fluid">
        {/* <!--begin::Toolbar-->  */}
        <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
          {/* <!--begin::Toolbar container-->  */}
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-flex flex-stack"
          >
            {/* <!--begin::Page title-->  */}
            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
              {/* <!--begin::Title-->  */}
              <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                Battles Details
              </h1>
            </div>
            {/* <!--end::Page title-->  */}
            {/* <!--begin::Actions-->  */}
          </div>
          {/* <!--end::Toolbar container-->  */}
        </div>
        {/* <!--end::Toolbar-->  */}
        {/* <!--begin::Content-->  */}
        <div id="kt_app_content" className="app-content flex-column-fluid">
          {/* <!--begin::Content container-->  */}
          <div
            id="kt_app_content_container"
            className="app-container container-xxl"
          >
            {/* <!--begin::Card-->  */}
            <div className="card card-flush">
              {/* <!--begin::Card header-->  */}
              <div className="card-header mt-6">
                {/* <!--begin::Card title-->  */}
                <div className="card-title"></div>
                {/* <!--end::Card title-->  */}
                {/* <!--begin::Card toolbar-->  */}
                <div className="card-toolbar"></div>
              </div>
              {/* <!--end::Card header-->  */}
              {/* <!--begin::Card body-->  */}
              <div className="card-body pt-0">
                <div className="row justify-content-center  align-items-center">
                  <div className="col-6">
                    <div className="row justify-content-center">
                      {/* <div className='col-2'>
                        <img src='' alt='' />
                      </div> */}
                      <div className="col-10">
                        {/* blank.png */}
                        <div style={{ width: "30px" }}>
                          <img
                            style={{ width: "100%", borderRadius: "50%" }}
                            src="/media/blank.png"
                            alt=""
                          />
                        </div>

                        {details?.ownerresult == "win" ? (
                          <div className="row">
                            <div className="col-1">
                              <div style={{ width: "30px" }}>
                                <img
                                  style={{ width: "100%" }}
                                  src="/media/ic/winner.png"
                                  alt=""
                                />
                              </div>
                            </div>

                            <div className="col-11">
                              <p>Winner</p>
                            </div>
                          </div>
                        ) : details?.ownerresult == "loss" ? (
                          <div className="row">
                            <div className="col-1">
                              <div style={{ width: "30px" }}>
                                <img
                                  style={{ width: "100%" }}
                                  src="/media/ic/losser.jpg"
                                  alt=""
                                />
                              </div>
                            </div>

                            <div className="col-11">
                              <p>Loser</p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* <div className='row'>
                          <div className='col-1'>
                          <div style={{width:"30px"}}>
                            <img style={{width:"100%"}} src='/media/ic/winner.png' alt='' />
                          </div>
                          </div>
                         
                          <div className='col-11'>
                            <p>Winner</p>
                          </div>
                        </div> */}

                        <p>{details?.owner?.uid}</p>
                        <p>{ maskNumber(details?.owner?.number)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row justify-content-center">
                      {/* <div className='col-2'>
                        <img src='' alt='' />
                      </div> */}
                      <div className="col-10 ">
                        <div style={{ width: "30px" }}>
                          <img
                            style={{ width: "100%", borderRadius: "50%" }}
                            src="/media/blank.png"
                            alt=""
                          />
                        </div>
                        {details?.playeroneresult == "win" ? (
                          <div className="row">
                            <div className="col-1">
                              <div style={{ width: "30px" }}>
                                <img
                                  style={{ width: "100%" }}
                                  src="/media/ic/winner.png"
                                  alt=""
                                />
                              </div>
                            </div>

                            <div className="col-11">
                              <p>Winner</p>
                            </div>
                          </div>
                        ) : details?.playeroneresult == "loss" ? (
                          <div className="row">
                            <div className="col-1">
                              <div style={{ width: "30px" }}>
                                <img
                                  style={{ width: "100%" }}
                                  src="/media/ic/losser.jpg"
                                  alt=""
                                />
                              </div>
                            </div>

                            <div className="col-11">
                              <p>Loser</p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <p>{details?.playerone?.uid}</p>
                        <p>{ maskNumber(details?.playerone?.number)}</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className='col-4'>
                    <div className='row'>Win</div>
                  </div> */}

                  {/* <div className='col-4'>
                    <div className='row'>
                      <div className='col-6'>Loss</div>
                      <div className='col-6'>verify</div>
                    </div>
                  </div> */}
                  <div className="col-12">
                    <table className="userDetailTable">
                      <tr className="userDetailTablet">
                        <th className="userDetailTablet2">Battle Details</th>
                        <th className="userDetailTablet2"></th>
                        <th className="userDetailTablet2"></th>
                        <th className="userDetailTablet2"></th>
                        {/* <th className='userDetailTablet2'></th>
                        <th className='userDetailTablet2'></th>
                        <th className='userDetailTablet2'></th> */}
                      </tr>
                      <tr>
                        <td>Battle id</td>
                        <td>{details?._id}</td>
                        <td>Labby Id</td>
                        <td>{details?.roomcode}</td>
                      </tr>
                      <tr>
                        <td>Creator id</td>
                        <td>
                          <Link to={"/user-details/" + details?.owner?.uid}>
                            {" "}
                            {details?.owner?._id}{" "}
                          </Link>{" "}
                        </td>
                        <td>Joiner Id</td>
                        <td>
                          <Link to={"/user-details/" + details?.playerone?.uid}>
                            {" "}
                            {details?.playerone?._id}
                          </Link>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>Creator Uid</td>
                        <td>{details?.owner?.uid}</td>
                        <td>Joiner Uid</td>
                        <td>{details?.playerone?.uid}</td>
                      </tr>
                      <tr>
                        <td>Creator Result</td>
                        <td>{details?.ownerresult}</td>
                        <td>Joiner Result</td>
                        <td>{details?.playeroneresult}</td>
                      </tr>
                      <tr>
                        <td>Entry Fee</td>
                        <td>{details?.amount}</td>
                        <td>Prize</td>
                        <td>{details?.prize}</td>
                      </tr>
                      <tr>
                        <td>Game Status</td>
                        <td>{details?.status}</td>
                        <td>Is Running</td>
                        <td>
                          {details?.status == "running"
                            ? "running"
                            : "not running"}
                        </td>
                      </tr>
                      <tr>
                        <td>Player One Image</td>
                        <td>
                          {" "}
                          <div style={{ width: "400px" }}>
                            <img
                              style={{ width: "100%" }}
                              src={URL.API_BASE_URL + details.ownerssimg}
                              alt=""
                            />
                          </div>
                        </td>
                        <td>Player Two Image</td>
                        <td>
                          {" "}
                          <div style={{ width: "400px" }}>
                            <img
                              style={{ width: "100%" }}
                              src={URL.API_BASE_URL + details.playeronessimg}
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  {/* <div className='col-4'>
                    <table className='userDetailTable'>
                      <tr className='userDetailTablet'>
                        <th className='userDetailTablet2'>Kyc Doucument Details</th>
                        <th className='userDetailTablet2'></th>
                      </tr>
                      <tr>
                        <td>Doucument Name</td>
                        <td>v3</td>
                      </tr>
                    </table>
                  </div>
                  <div className='col-4'>
                    <table className='userDetailTable'>
                      <tr className='userDetailTablet'>
                        <th className='userDetailTablet2'>Kyc Doucument Details</th>
                        <th className='userDetailTablet2'></th>
                      </tr>
                      <tr>
                        <td>Doucument Name</td>
                        <td>v3</td>
                      </tr>
                    </table>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* <!--end::Content container-->  */}
        </div>
        {/* <!--end::Content-->  */}
      </div>
    </div>
  );
}

export default Bettalview;
