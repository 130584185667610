import React from "react";

const SidebarFooter = () => {
  return (
    <div className="siderbar_footer">
      {/* © {new Date().getFullYear()}{" "}
      <a href="https://poizegames.com" target="_blank" rel="noreferrer">
      poizegames.com
      </a>
      <br />
      Designed & Developed by
      <br />
      <a href="https://abym.in" target="_blank" rel="noreferrer">
      poizegames.
      </a> */}
    </div>
  );
};

export default SidebarFooter;
